<template>
  <transition name="slide-fade">
    <div class="ml-filters__filter ml-filters__filter--status">
      <div class="ml-filters__status-mobile">
        <div class="bb-filter-item">
          <div class="bb-filter-item__label">{{ $t("generic.status") }}</div>
          <div class="bb-filter-radio">
            <div v-for="stateObj in states" class="bb-filter-radio__item">
              <input
                :id="'state_' + stateObj.name"
                :key="stateObj.id"
                :checked="selected === stateObj.id"
                @change="applyFilter(stateObj)"
                type="radio"
              />
              <label :for="'state_' + stateObj.name" class="w-form-label">{{
                stateObj.name
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-filters__status-desk">
        <div class="bb-filter-item bb-filter-item--inline">
          <div class="bb-filter-item__label">{{ $t("generic.status") }}</div>
          <div class="bb-filter" v-click-outside="config">
            <div
              class="bb-filter__select"
              @click="toggle"
              :class="toggleFilter ? 'bb-filter__select--active' : ''"
            >
              {{ label }}
              <img
                src="../../../assets/img/ico/ico-chevron-up.svg"
                loading="lazy"
                alt=""
                width="16px"
                :class="
                  toggleFilter
                    ? 'bb-filter__chevron bb-filter__chevron--active'
                    : 'bb-filter__chevron '
                "
              />
            </div>
            <div class="bb-filter__items" v-show="toggleFilter">
              <div
                class="bb-filter__item bb-filter__item--option"
                v-for="(state, index) in states"
                :key="index"
                @click="applyFilter(state)"
                :class="state.id == selected ? 'bb-filter__item--active' : ''"
              >
                <span v-text="state.name" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import IsFilter from "./Mixins/IsFilter";
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";
import { mapGetters } from "vuex";

export default {
  name: "StateFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside, IsFilter],
  data() {
    return {
      selected: 99,
    };
  },
  computed: {
    ...mapGetters({
      ownerProductFilters: "ownerProductFilters",
    }),
    label() {
      return this.selected === 99
        ? this.$t("generic.all")
        : this.states.find((state) => state.id === this.selected).name;
    },
  },
  methods: {
    applyFilter(state) {
      this.selected = state.id;
      let newFilters = [...this.ownerProductFilters];

      const found = newFilters.find((filter) => filter.name === "state");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      if (this.selected !== 99) {
        newFilters.push({
          name: "state",
          values: {
            id: state.id,
          },
        });
      }
      this.$setOwnerProductFilters(newFilters);
      this.close();
    },
  },
  created() {
    this.states = [
      {
        id: 99,
        name: this.$t("generic.all"),
      },
      {
        id: 1,
        name: this.$t("generic.available"),
      },
      {
        id: 2,
        name: this.$t("generic.purchased"),
      },
      {
        id: 3,
        name: this.$t("generic.reserved"),
      },
    ];
  },
  mounted() {
    const stateFound = this.ownerProductFilters.find((filter) => filter.name === "state");
    if (stateFound) {
      this.selected = stateFound.values.id;
    }
  },
};
</script>
